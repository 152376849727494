.service-category-div {
  padding: 50px;
}
.service-category-text-field {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 10px;
}
.service-category-text-field-row {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
}
.service-category-image-field {
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-top: 50px;
  margin-left: 20px;
  align-items: center;
}
.service-category-media-div {
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  border-radius: 10px;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .service-category-text-field {
    display: flex;
    flex-direction: column;
  }
  .service-category-image-field {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .service-category-div {
    padding: 10px;
    margin-top: 20px;
  }
  .service-category-text-field {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .service-category-image-field {
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-bottom: 10px;
    margin-top: 50px;
  }
}
