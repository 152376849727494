.booking-detail-row {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.booking-detail-element {
  display: flex;
  gap: 5px;
  flex-direction: row;
  grid-gap: 10px;
  justify-content: flex-start;
  width: 50%;
}
.booking-details {
  display: flex;
  flex-direction: column;
}
.services-search-div {
  display: flex;
  justify-content: flex-start;
}
.create-booking-main-div {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  padding: 10px;
  padding-bottom: 30px;
}
.create-booking-div {
  margin-top: -10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.create-booking-content-1 {
  width: 100%;
  display: flex;
  /* justify-content: center; */
  gap: 10px;
}

.create-booking-cart {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-top: 5px;
  gap: 10px;
  margin-top: 60px;
}
.create-booking-cart-btn-div {
  display: flex;
  justify-content: center;
  gap: 10px;
}
.create-booking-customer {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-top: 5px;
  gap: 10px;
  margin-top: 60px;
}
.create-booking-cart-btn-div {
  display: flex;
  justify-content: center;
  gap: 10px;
}
.booking-date-startTime-endTime-div{
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .create-booking-content-1 {
    flex-direction: column;
  }
  .create-booking-cart {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
  }
  .create-booking-customer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .booking-detail-row {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .booking-detail-element {
    display: flex;
    gap: 5px;
    flex-direction: row;
    grid-gap: 10px;
    width: 100%;
    flex-wrap: wrap;
  }
  .services-search-div {
    display: flex;
    justify-content: flex-start;
  }
  .create-booking-content-1 {
    flex-direction: column;
  }
  .create-booking-cart {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
  }
  .create-booking-customer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
  }
}
