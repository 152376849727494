.team-members-div {
  padding: 50px;
}
.team-members-text-field {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 10px;
}
.team-members-text-field-row {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
}
.team-members-text-field-column {
  width: 100%;
  gap: 5px;
}
.team-members-image-field {
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-top: 50px;
  margin-left: 20px;
  align-items: center;
}
.team-members-media-div {
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  border-radius: 10px;
}
.team-members-text-field-row-time-picker {
  display: flex;
  gap: 30px;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .team-members-text-field {
    display: flex;
    flex-direction: column;
  }
  .team-members-image-field {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  }
  .team-members-text-field-row-time-picker {
    display: flex;
    gap: 30px;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .team-members-div {
    padding: 10px;
    margin-top: 20px;
  }
  .team-members-text-field {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .team-members-image-field {
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-bottom: 10px;
    margin-top: 50px;
  }
  .team-members-text-field-row-time-picker {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
