.product-div {
  padding: 50px;
}
.product-text-field {
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-bottom: 10px;
}
.product-image-field {
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-top: 50px;
  margin-left: 20px;
  align-items: center;
}
.product-video-field {
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-bottom: 10px;
  margin-top: 40px;
}
.product-text-field-row {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
}
.product-multiple-image-field {
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-bottom: 10px;
  justify-content: flex-start;
  align-items: center;
  /* flex-wrap: wrap; */
  margin-top: 40px;
}
.multiple-product-images {
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  /* height: 100%; */
}
.product-image-upload-field {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  margin-top: 10px;
  margin-left: 20px;
}
.product-cross-icon {
  position: absolute;
  top: -30;
  right: 10;
  cursor: "pointer";
}
.product-img-size {
  width: calc(210px - 30px);
  height: 130px;
  border-radius: 10px;
}
.product-video-size {
  width: calc(200px - 20px);
  height: 130px;
}

.addon-div {
  margin-top: 30px;
}
.product-media-div {
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  border-radius: 10px;
}
.image-upload-btn-div {
  width: calc(210px - 30px);
  height: 155px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px dashed black;
}
.product-text-field-product-type {
  margin-top: 10px;
}
.variable-product-main-div {
  width: 100%;
  /* max-height: 500px; */
  display: flex;
  justify-content: center;
  /* align-items: center; */
  margin-top: 5px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  padding: 10px;
  border-radius: 10px;
}
.variable-product-sub {
  width: 20%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  border-radius: 10px;
}
.variable-product-sub-value {
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  border-radius: 10px;
}
.variable-product-sub-value-sub {
  width: 100%;
  display: flex;
  gap: 10px;
}
.variable-product-sub-value-sub-select {
  width: 60%;
}
.variable-product-sub-value-accordion {
  max-height: 400px;
  /* overflow-y: scroll; */
}
.accordion-body {
  max-height: 400px;
}
.variable-product-sub-value-sub-variation {
  width: 100%;
  display: flex;
  gap: 40px;
  flex-direction: column;
  overflow-x: scroll;
  max-height: 600px;
}
.variable-product-sub-value-sub-variation-added {
  width: 100%;
  display: flex;
  gap: 10px;
  flex-direction: column;
  /* overflow-x:scroll ; */
}
.table-select-field {
  width: 100%;
}


@media (min-width: 768px) and (max-width: 1024px) {
  .product-text-field {
    display: flex;
    flex-direction: column;
  }
  .product-image-field {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  }
  .product-video-field {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  }
  .product-multiple-image-field {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  }
  .multiple-product-images {
    justify-content: center;

    /* height: 100%; */
  }
  .product-div {
    padding: 50px;
  }
  .variable-product-main-div {
    flex-direction: column;
  }
  .variable-product-sub {
    width: 100%;
  }
  .variable-product-sub-value {
    width: 100%;
    padding: 5px;
  }
  .variable-product-sub-value-sub {
    flex-direction: column;
  }
  .variable-product-sub-value-sub-select {
    width: 100%;
  }
  .table-select-field {
    width: 150px;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .product-div {
    padding: 10px;
    margin-top: 20px;
  }
  .product-text-field {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .product-image-field {
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-bottom: 10px;
    margin-top: 50px;
  }
  .product-video-field {
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-bottom: 10px;
  }
  .product-multiple-image-field {
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-bottom: 10px;
  }
  .product-img-size {
    width: calc(210px - 30px);
    height: 130px;
  }
  .product-video-size {
    width: calc(200px - 20px);
    height: 130px;
  }
  .multiple-product-images {
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    /* height: 100%; */
  }
  .variable-product-main-div {
    flex-direction: column;
  }
  .variable-product-sub {
    width: 100%;
  }
  .variable-product-sub-value {
    width: 100%;
    padding: 5px;
  }
  .variable-product-sub-value-sub {
    flex-direction: column;
  }
  .variable-product-sub-value-sub-select {
    width: 100%;
  }
  .table-select-field {
    width: 150px;
  }
  .product-edit-title {
    font-size: 0.8rem;
  }
  .variable-product-sub-value-sub-variation {
    gap: 20px;
  }
}
