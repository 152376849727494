.chat-main-div {
  width: 100%;
  position: relative;
  /* height: 100%; */
  /* max-height: 600px; */
  background-color: white;
  /* padding-bottom: 80px; */

  /* background-color: #404040; */
}
.chat-container-div {
  display: flex;
  flex-direction: row;
}
.chat-container-div1 {
  width: 50%;
}
.chat-container-div2 {
  width: 50%;
}
.chat-screen-div {
  position: relative;
  width: 100%;
  /* max-height: 500px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding-bottom: 50px; */
  padding: 10px;
  overflow-y: scroll;
  max-height: calc(100vh - 201px);
  padding-bottom: 200px;
  /* margin-bottom: 80px; */
}
.chat-screen-1 {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px;
  gap: 5px;
}
.chat-screen-2 {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-top: 20px;
  gap: 5px;
}
.chat-screen-1-h5 {
  background-color: #1998e6;
  width: 300px;
  /* min-height: 40px; */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  color: white;
  margin: 0px;
  padding: 10px;
}
.chat-screen-1-image {
  width: 350px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.chat-screen-2-h5 {
  background-color: #ddd;
  width: 300px;
  /* min-height: 40px; */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  color: black;
  margin: 0px;
  padding: 10px;
}
.chat-screen-2-h5-systemCustomer {
  background-color: #ddd;
  width: 300px;
  /* min-height: 40px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  color: black;
  margin: 0px;
  padding: 10px;
}

.chat-screen-1-h5-payment-show {
  background-color: #1998e6;
  width: 300px;
  /* min-height: 40px; */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  color: white;
  margin: 0px;
  padding: 10px;
}
.chat-screen-payment-show {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.chat-screen-footer-div {
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: 20px; */
  gap: 10px;
  bottom: 0;
  padding-bottom: 20px;
}
.chat-message-input {
  background-color: #282828;
  height: 40px;
  /* width: 70%; */
  width: 350px;
  border-radius: 10px;
  padding: 10px;
  border: none;
  color: white;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .chat-screen-1 {
    width: 90%;
  }
  .chat-screen-2 {
    width: 90%;
  }
  .chat-screen-1-h5 {
    width: 350px;
  }
  .chat-screen-2-h5 {
    width: 350px;
  }
  .chat-screen-2-h5-systemCustomer {
    width: 350px;
  }
  .chat-screen-footer-div {
    gap: 5px;
  }
  .chat-message-input {
    width: 600px;
  }
  .chat-container-div {
    display: flex;
    flex-direction: column;
  }
  .chat-container-div1 {
    width: 100%;
  }
  .chat-container-div2 {
    width: 100%;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .chat-screen-1 {
    width: 90%;
  }
  .chat-screen-2 {
    width: 90%;
  }
  .chat-screen-1-h5 {
    width: 240px;
  }
  .chat-screen-2-h5 {
    width: 240px;
  }
  .chat-screen-2-h5-systemCustomer {
    width: 240px;
  }
  .chat-screen-footer-div {
    /* margin-top: 100px; */
    gap: 3px;
  }
  .chat-message-input {
    width: 250px;
  }
  .chat-container-div1 {
    width: 100%;
  }
  .chat-container-div {
    display: flex;
    flex-direction: column;
  }
  .chat-container-div1 {
    width: 100%;
  }
  .chat-container-div2 {
    width: 100%;
  }
  .chat-screen-div {
    /* max-height: 400px; */
    max-height: calc(100vh - 201px);
    padding-bottom: 200px;
  }
  .chat-main-div {
    /* max-height: 500px; */
    overflow-y: auto;
  }
  .chat-screen-1-image {
    width: 280px;
  }
}
