.orders-div {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  height: 100vh;
}

.orders-list {
  width: 100%;
  height: 100%;
}

.order_main_content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.order-detail-row {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.order-detail-element {
  display: flex;
  gap: 5px;
  flex-direction: row;
  grid-gap: 10px;
  justify-content: flex-start;
  width: 50%;
}
.order-details {
  display: flex;
  flex-direction: column;
}
.order-addon-h6 {
  font-size: 0.8rem;
}
.order_update_btn_div {
  display: flex;
  justify-content: space-between;
  margin-right: 10px;
  align-items: center;
}
.item_detail_title {
  font-size: 0.7rem;
  font-weight: 500;
  color: black;
  margin: 0px;
  padding: 0px;
}
.item_detail_des {
  font-size: 0.8rem;
  margin: 0px;
  padding: 0px;
}
.total_amt_title {
  font-size: 0.7rem;
  font-weight: 500;
  color: black;
  margin: 0px;
  padding: 0px;
}
.total_amt_des {
  font-size: 0.8rem;
}
.order-customer-div {
  display: flex;
  gap: 10px;
}
.create-order-main-div {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  padding: 10px;
  padding-bottom: 30px;
}
.create-order-div {
  margin-top: -10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.create-order-content-1 {
  width: 100%;
  display: flex;
  /* justify-content: center; */
  gap: 10px;
}
.create-order-content-2 {
  width: 100%;
  display: flex;
  /* justify-content: center; */
  gap: 10px;
}
.create-order-product {
  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: center; */
  width: 50%;
  margin-top: 5px;
  gap: 10px;

  /* height: 400px; */
}
.create-order-cart {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-top: 5px;
  gap: 10px;
  margin-top: 60px;
}
.create-order-cart-btn-div {
  display: flex;
  justify-content: center;
  gap: 10px;
}
.textArea {
  display: flex;
  width: 100%;
  margin-top: 10px;
  padding:15px;
  border-radius: 5px;
}
.addButton {
  margin-left: 10px;
  margin-top: 20px;
  height: 60px;
  width: 70%;
  background-color: rgb(3, 3, 3);
}
.dateContainer {
  display: flex;
  border-bottom: 1px dotted #999;
  max-width: 300px;
}
.date {
  margin-right: 5px;
  margin-bottom: 5px;
  color: #888;
  opacity: 0.7;
  font-weight: lighter;
  font-size: 10px;
}
.delete_note {
  color: #a00;
  font-size: 10px;
}

.messageBy {
  margin-right: 5px;
  margin-bottom: 5px;
  color: #888;
  opacity: 0.7;
  font-weight: lighter;
  font-size: 10px;
}

.box {
  color: #0f0f0f;
  background-color: #d7cad2;
  /* height: 100%; */
  display: flex;
  /* justify-content: center; */
  align-items: center;
  margin: 2em;
  margin-left: 0em;
  margin-top: 10px;
  font-family: "Outfit", sans-serif;
  text-align: justify;
}

.box {
  position: relative;
  width: 300px;
  /* height: auto; */
  border: 2px solid #fff;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.box::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 70%;
  width: 25px;
  height: 25px;
  background-color: #d7cad2;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  transform: rotate(45deg);
  margin-top: -12px;
}

.create-order-customer {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-top: 5px;
  gap: 10px;
  margin-top: 60px;
}
.create-order-address {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-top: 5px;
  gap: 10px;
  margin-top: 20px;
}
.order-text-field {
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-bottom: 10px;
}

.order-text-field-row {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.product-search-div {
  display: flex;
  justify-content: flex-start;
}
.customer-search-div {
  display: flex;
  justify-content: flex-start;
}

.cardStyle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /*Adjust as needed; */
  background-color: #f0f0f0;
}

.invoiceCardStyle {
  padding-bottom: 5.7vh;
  overflow-x: scroll;
}
.buttonStyle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.order-variant-p {
  font-size: 0.8rem;
  margin: 0px;
}

@media (min-width: 768px) and (max-width: 1024px) {
  /* .orders-div {
    display: flex;
    flex-direction: column;
  }
  .order-detail-element {
    justify-content: space-between;
  } */
  .item_detail_title {
    font-size: 0.7rem;
  }
  .item_detail_des {
    font-size: 0.6rem;
  }
  .total_amt_title {
    font-size: 0.7rem;
  }
  .total_amt_des {
    font-size: 0.6rem;
  }
  .create-order-content-1 {
    flex-direction: column;
  }
  .create-order-content-2 {
    flex-direction: column;
  }
  .create-order-product {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    /* height: 400px; */
  }
  .create-order-cart {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
  }
  .create-order-customer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
  }
  .create-order-address {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
  }
  .order-text-field {
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .order-detail-row {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .order-detail-element {
    display: flex;
    gap: 5px;
    flex-direction: row;
    grid-gap: 10px;
    width: 100%;
    flex-wrap: wrap;
  }
  .address-h6 {
    word-wrap: break-word;
    width: 225px;
  }
  .order-addon-h6 {
    font-size: 0.7rem;
  }
  .item_detail_title {
    font-size: 0.7rem;
  }
  .item_detail_des {
    font-size: 0.6rem;
  }
  .total_amt_title {
    font-size: 0.7rem;
  }
  .total_amt_des {
    font-size: 0.6rem;
  }
  .create-order-content-1 {
    flex-direction: column;
  }
  .create-order-content-2 {
    flex-direction: column;
  }
  .create-order-product {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    /* height: 400px; */
  }
  .create-order-cart {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
  }
  .create-order-customer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
  }
  .create-order-address {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
  }
  .order-text-field {
    display: flex;
    flex-direction: column;
  }
  .product-search-div {
    display: flex;
    justify-content: flex-start;
  }
  .customer-search-div {
    display: flex;
    justify-content: flex-start;
  }
  .order-variant-p {
    font-size: 0.6rem;
  }
  .textArea {
    display: flex;
    width: 100%;
    margin-top: 10px;
    margin-left: 2px;
    padding: 10px;
  }
}
