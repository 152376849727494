.listgroupitem-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px;
  width: 100%;
}

.eachitem-div {
  display: flex;
  flex-direction: row;
  grid-gap: 10px;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
}

.basic-details-button {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;
}

.form-color-div {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding-top: 10px;
}
.form-color {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.form-color-input {
  width: 150px;
}
.imageKit-div {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px;
  gap: 120px;
}
.each-Image-kit {
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  border-radius: 10px;
  padding: 20px;
}
.each-textfield {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
}

.date-input-dash {
  padding: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  margin-bottom: 10px;
}
.date-input-div-dash {
  display: flex;
  gap: 5px;
  width: 100%;
}

.cancel {
  display: flex;
  justify-content:flex-end;
}
/* .title-providername{
    font-size: 0.8;
} */

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .listgroupitem-row {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }

  /* .title-providername{
        font-size: 0.6rem;
    } */
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .listgroupitem-row {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
  .eachitem-div {
    justify-content: space-between;
    padding-bottom: 5px;
    width: 100%;
  }
  .eachitem-div-h6 {
    font-size: 0.7rem;
    display: flex;
    justify-content: flex-start;
    width: 50%;
    flex-wrap: wrap;
  }

  .form-color-div {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    /* justify-content: start; */
    /* align-items: center; */
    padding-top: 10px;
  }
  .form-color {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  .form-color-input {
    width: 150px;
  }
  .imageKit-div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* padding: 10px; */
    gap: 30px;
  }
  .each-Image-kit {
    font-size: 0.8rem;
    /* width: 100%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* gap: 40px; */
  }
  .date-input-dash {
    padding: 4px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 15px;
    margin-bottom: 0px;
    margin: 0px;
   
  }
  .date-input-div-dash {
    display: flex;
    flex-direction: column;
    gap: 0px;
    width: 50%;
    margin: 0px;
    padding: 0px;
  }
  /* .title-providername{
    font-size: 0.6rem;
} */
}
