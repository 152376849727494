.variant-div {
  padding: 50px;
}
.variant-text-field {
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-bottom: 10px;
}

.variant-text-field-row {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
}
.variant-text-field-row-btn {
  display: flex;
  gap: 5px;
  align-items: center;
}
.variant-value-add-div {
  width: 100%;
}
.variant-ul-div {
  margin-top: 20px;
}
.variant-ul {
  display: flex;
  gap: 15px;
  display: flex;
  flex-wrap: wrap;
}
.variant-li {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
}
.variant-text-field-value-div {
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .variant-div {
    padding: 30px;
  }
  .variant-text-field {
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .variant-div {
    padding: 20px;
  }
  .variant-text-field {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
}
