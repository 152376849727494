.categories-text-field {
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-bottom: 10px;
}

.categories-text-field-row {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.categories-toggle-div {
  display: flex;
  gap: 50px;
  min-width: 500px;
  align-items: center;
  justify-content: center;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .categories-text-field {
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .categories-text-field {
    display: flex;
    flex-direction: column;
  }
  .categories-toggle-div {
    display: flex;
    flex-direction: column;
    gap: 0px;
    min-width: 200px;
    align-items: center;
  }
  .category-edit-title {
    font-size: 0.7rem;
  }
}
